import Link from "next/link";

export enum BrandColor {
    BLACK = "black",
    RED = "red"
}

type ActionCardProps = {
    headline: string
    headlineColor: BrandColor
    subtitle: string
    actionLink: string
    actionText: string
}
function toTailwindClass(brandColor: BrandColor): string {
    if (brandColor === BrandColor.BLACK) {
        return 'bg-black'
    } else if (brandColor === BrandColor.RED) {
        return 'bg-jump'
    } else {
        return 'bg-black'
    }
}
export default function ActionCard(props: ActionCardProps) {

    return             <div className={"flex flex-col w-full bg-gray-100 gap-y-4"}>
        <div className={`grow ${toTailwindClass(props.headlineColor)} text-white text-center p-2 flex flex-col rounded-t-2xl`}>
            <div className={"text-2xl font-bold"}>{props.headline}</div>
            <div className={"text-sm"}>{props.subtitle}</div>
        </div>
        <div className={"flex gap-x-4 justify-center pb-4"}>
            <Link className={"btn btn-primary"} href={props.actionLink}>{props.actionText}</Link>
        </div>
    </div>
}