"use client"
import {useEffect, useState} from "react";
import ActionCard, {BrandColor} from "../../../components/action-card";

export default function Login() {
    const [homePage, setHomePage] = useState("")
    useEffect(() => {
        setHomePage(`${window.location.protocol}//${window.location.hostname}`)
    }, [])
    return <>
        <div className={"p-4 m-auto max-w-2xl flex gap-x-6"}>
            <ActionCard headline={"Customer"}
                        headlineColor={BrandColor.RED}
                        subtitle={'You buy tickets to comedy shows.'}
                        actionLink={`${process.env.NEXT_PUBLIC_MEDUSA_BASE_URL}/mct/login/customer`}
                        actionText={"Login"}/>

            <ActionCard headline={"Admin"}
                        headlineColor={BrandColor.BLACK}
                        subtitle={'You create and manage shows.'}
                        actionLink={`${process.env.NEXT_PUBLIC_MEDUSA_BASE_URL}/mct/login/admin`}
                        actionText={"Login"}/>
        </div>
    </>
}